import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/kyc-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycList")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardManagement/Dashboard")
    ),
  },

  {
    exact: true,
    path: "/view-kyc",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKyc")
    ),
  },

  {
    exact: true,
    path: "/view-user-fund-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/ViewUserDetails")
    ),
  },

  {
    exact: true,
    path: "/platform-coin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/PlatformCoinBalance")
    ),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserDetailsViewTabs")
    ),
  },
  {
    exact: true,
    path: "/admin-commission-transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/HotWalletManagement/AdminCommissionEarned"
      )
    ),
  },
  {
    exact: true,
    path: "/fee-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FeeManagement/FeeManagementTabs")
    ),
  },
  {
    exact: true,
    path: "/wallet-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/WalletManagement/WalletTransactionListing"
      )
    ),
  },
  {
    exact: true,
    path: "/user-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/subadmin-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/SubAdminList")
    ),
  },
  {
    exact: true,
    path: "/bank-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/UserBankList")
    ),
  },
  {
    exact: true,
    path: "/view-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/ViewSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/admin-bank-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/AdminBankDetail")
    ),
  },
  {
    exact: true,
    path: "/add-bank",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/AddBank")
    ),
  },
  {
    exact: true,
    path: "/hot-wallet-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/HotWalletManagement/WalletManagementTabs"
      )
    ),
  },
  {
    exact: true,
    path: "/add-subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdmin/AddSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/hot-cold-wallet-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/HotWalletManagement/HotWalletToColdWalletTransaction"
      )
    ),
  },
  {
    exact: true,
    path: "/admin-commission",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagement/AdminCommission")
    ),
  },
  {
    exact: true,
    path: "/commission-transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/HotWalletManagement/CommissionTransactionHistory"
      )
    ),
  },
  {
    exact: true,
    path: "/fund-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FundManagement/FundManagementTabs")
    ),
  },

  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },

  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
